import React from 'react';
import './team.css';
import {BsFacebook, BsTwitter, BsInstagram, BsLinkedin} from 'react-icons/bs';
// import {team} from './../../assets/img/team.jpg';
import team from './../../assets/img/team-2.jpg';

const Team = () => {
  return (
    <section id="team" class="team">
        <div className="container" data-aos="fade-up">
            <header className="section-header team-header">
                <h2>Team</h2>
                <p className='gradient__text'>Our hard working team</p>
            </header>

            <div class="row gy-4">


                <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                    <div class="member">
                        <div class="member-img">
                            <img src={team} class="img-fluid" alt="" />
                            <div class="social">
                                <a href="www.facebook.com"><BsTwitter /></a>
                                <a href="www.facebook.com"><BsFacebook /></a>
                                <a href="www.facebook.com"><BsInstagram /></a>
                                <a href="www.facebook.com"><BsLinkedin /></a>
                            </div>
                        </div>
                        <div class="member-info">
                            <h4>Imelda Amuron</h4>
                            <span>Chief Executive Officer</span>
                            <p>Velit aut quia fugit et et. Dolorum ea voluptate vel tempore tenetur ipsa quae aut. Ipsum exercitationem iure minima enim corporis et voluptate.</p>
                        </div>
                    </div>
                </div>


                <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                    <div class="member">
                        <div class="member-img">
                            <img src={team} class="img-fluid" alt="" />
                            <div class="social">
                                <a href="www.facebook.com"><BsTwitter /></a>
                                <a href="www.facebook.com"><BsFacebook /></a>
                                <a href="www.facebook.com"><BsInstagram /></a>
                                <a href="www.facebook.com"><BsLinkedin /></a>
                            </div>
                        </div>
                        <div class="member-info">
                            <h4>Imelda Amuron</h4>
                            <span>Product Manager</span>
                            <p>Quo esse repellendus quia id. Est eum et accusantium pariatur fugit nihil minima suscipit corporis. Voluptate sed quas reiciendis animi neque sapiente.</p>
                        </div>
                    </div>
                </div> 


                <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
                    <div class="member">
                        <div class="member-img">
                            <img src={team} class="img-fluid" alt="" />
                            <div class="social">
                                <a href="www.facebook.com"><BsTwitter /></a>
                                <a href="www.facebook.com"><BsFacebook /></a>
                                <a href="www.facebook.com"><BsInstagram /></a>
                                <a href="www.facebook.com"><BsLinkedin /></a>
                            </div>
                        </div>
                        <div class="member-info">
                            <h4>Imelda Amuron</h4>
                            <span>CTO</span>
                            <p>Vero omnis enim consequatur. Voluptas consectetur unde qui molestiae deserunt. Voluptates enim aut architecto porro aspernatur molestiae modi.</p>
                        </div>
                    </div>
                </div>


                <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
                    <div class="member">
                        <div class="member-img">
                            <img src={team} class="img-fluid" alt="" />
                            <div class="social">
                                <a href="www.facebook.com"><BsTwitter /></a>
                                <a href="www.facebook.com"><BsFacebook /></a>
                                <a href="www.facebook.com"><BsInstagram /></a>
                                <a href="www.facebook.com"><BsLinkedin /></a>
                            </div>
                        </div>
                    <div class="member-info">
                        <h4>Imelda Amuron</h4>
                        <span>Accountant</span>
                        <p>Rerum voluptate non adipisci animi distinctio et deserunt amet voluptas. Quia aut aliquid doloremque ut possimus ipsum officia.</p>
                    </div>
                </div>
            </div>



            </div>
        </div>
    </section>
  )
}

export default Team;