import React from 'react';
import './header.css';
import img1 from './../../assets/img/hero-img.png';
// import people from './../../assets/img/people.png';
import {BsArrowRightCircleFill} from 'react-icons/bs';
const Header = () => {
  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">We partner with businesses seeking advanced solutions for a competitive edge</h1>
        <p>
        In today's digital landscape, every business needs to harness technology to its maximum potential. We offer high-quality services for every stage of software solution development, from strategy and development to integration, implementation, and ongoing support.
        </p>

        <div className="gpt3__header-content__input">
          <a href='#about' className='btn btn-primary'><span>Get Started &nbsp;&nbsp;&nbsp;</span><BsArrowRightCircleFill /></a>
        </div>

        {/* <div className="gpt3__header-content__people">
          <img src={people} alt='people'/>
          <p>1,600 people requested access a visit in last 24 hours</p>
        </div> */}
      </div>

      <div className="gpt3__header-image">
        <img src={img1} alt=' '/>
      </div>


    </div>
  )
}

export default Header;