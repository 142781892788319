import React, { useState }from 'react';
import './navbar.css';
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';
// import {BsList} from 'react-icons/bs';
// import logo2 from './../../assets/img/logo.png';
// import logo from './../../assets/img/Kesilo-logo.png';
import logo from './../../assets/img/kesilo-logo-5.png';

const Navbar = () => {
  const [isMobile, setActive] = useState(false);
  // const [toggleMenu, setToggleMenu] = useState(false);
  const toggleMobile = ()=>{
    setActive(!isMobile);
  };

  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }
  const on = (type, el, listener, all = false) => {
    if (all) {
      select(el, all).forEach(e => e.addEventListener(type, listener))
    } else {
      select(el, all).addEventListener(type, listener)
    }
  }

  const scrollto = (el) => {
    let header = select('#header')
    let offset = header.offsetHeight

    if (!header.classList.contains('header-scrolled')) {
      offset -= 10
    }

    let elementPos = select(el).offsetTop
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    })
  }

  on('click', '.scrollto', function(e) {
    if (select(this.hash)) {
      e.preventDefault()

      let navbar = select('#navbar')
      if (navbar.classList.contains('navbar-mobile')) {
        navbar.classList.remove('navbar-mobile')
        setActive(!isMobile);
      }
      scrollto(this.hash)
    }
  }, true)
  
  return (
    <header className='header' id='header'>
      <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
        <a href="/" class="logo d-flex align-items-center">
          <img src={logo} alt="" />
          <span>Kesilo</span>
        </a>
        <nav id="navbar" className={isMobile?"navbar navbar-mobile":"navbar"}>
          <ul>
            <li><a class="nav-link scrollto active" href="#home">Home</a></li>
            <li><a class="nav-link scrollto" href="#about">About Us</a></li>
            <li><a class="nav-link scrollto" href="#services">Services</a></li>
            <li><a class="nav-link scrollto" href="#hero">Portfolio</a></li>
            <li><a class="nav-link scrollto" href="#team">Team</a></li>
            <li><a class="nav-link scrollto" href="#contact">Contact Us</a></li>
            <li><a class="getstarted scrollto" href="#about">Get Started</a></li>
          </ul>
          <i class={isMobile?"mobile-nav-toggle hidden":"mobile-nav-toggle"} onClick={toggleMobile}><RiMenu3Line color='#fff' size={30} /></i>
          <i class={isMobile?"mobile-nav-toggle":"mobile-nav-toggle hidden"} onClick={toggleMobile}><RiCloseLine color='#fff' size={30} /></i>
        </nav>

      </div>
    </header>
  )
}

export default Navbar;