import React from 'react';
import { Header, Footer, Blog, Features, Possibility, About} from './containers';
import { Navbar, CTA, Team, Services, Contact } from './components';
import './App.css';

const App = () => {
  return (
    <div className='App'>
      <div className='gradient__bg'>
        <Navbar />
        <Header />
      </div>
      {/* <Brand /> */}
      <About />
      <Features />
      <Services />
      <Possibility />
      <CTA />
      <Blog />
      <Team />
      <Contact />
      <Footer />
    </div>
  )
}

export default App;