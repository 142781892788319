import React from 'react';
import Feature from '../../components/feature/Feature';
import './about.css';

const About = () => {
  return (
    <div className="gpt3__whatgpt3 section__margin" id="about">
      <div className="gpt3__whatgpt3-feature">
        <Feature 
          title="WHO WE ARE" 
          text="We are a software development company crafting a suite of versatile software tools, ensuring businesses have the resources they need to succeed. We aim to be your trusted partner, simplifying the complexities of the business world, so you can navigate with confidence and ease." 
        />
      </div>
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
        <p>Explore the Library</p>
      </div>
      <div className="gpt3__whatgpt3-container">
        <Feature 
          title="Vision" 
          text="Empowering Business Success Through Innovative Software Solutions." 
        />
        {/* <Feature 
          title="Mission" 
          text="Our mission is to empower businesses to thrive by providing comprehensive, all-in-one software solutions that streamline accounting, stock management, tax compliance, and offer invaluable business advisory services. We are committed to simplifying the complexities of financial management, enabling businesses to focus on growth and success. We strive to develop cutting-edge tools that support businesses in various aspects, ultimately making business management more efficient and accessible. Our mission is to be the trusted partner that businesses rely on to achieve their goals and reach new heights." 
        /> */}
        {/* <Feature 
          title="Mission"
          text="Our mission is to drive business excellence by delivering cutting-edge software solutions that optimize and simplify diverse operational facets. From managing finances and stock to ensuring tax compliance and offering expert guidance, we're dedicated to providing comprehensive tools that empower businesses to thrive, grow efficiently, and prioritize their core objectives."
        /> */}
        <Feature 
          title="Mission"
          text="Our mission is to enable business excellence through cutting-edge software solutions, streamlining operations from finance to stock management, ensuring tax compliance, and empowering growth."
        />
        <Feature 
          title="Slogan" 
          text="Simplifying Business, Amplifying Success." 
        />
      </div>
    </div>
  )
}

export default About;