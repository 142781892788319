import React from 'react';
import './article.css'

function Article() {
  return (
    <div>
      
    </div>
  )
}

export default Article
