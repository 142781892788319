import React from 'react';
import './footer.css';
// import gpt3Logo from '../../assets/img/logo.svg';
import logo from './../../assets/img/kesilo-logo-4.png';
import {BsFacebook, BsTwitter, BsInstagram, BsLinkedin,BsChevronRight} from 'react-icons/bs';

const Footer = () => {
  return (
    <footer id='footer' className='footer'>


      <div class="footer-newsletter">
        <div class="container">
          <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <h4>Our Newsletter</h4>
            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
          </div>
          <div class="col-lg-6">
            <form action="" method="post">
              <input type="email" name="email" /><input type="submit" value="Subscribe" />
            </form>
          </div>
          </div>
        </div>
      </div>

      <div class="footer-top">
        <div class="container">
          <div class="row gy-4">

            <div class="col-lg-5 col-md-12 footer-info">
              <a href="index.html" class="logo d-flex align-items-center">
                <img src={logo} alt="" />
                {/* <span>Kesilo</span> */}
              </a>
              <p>Cras fermentum odio eu feugiat lide par naso tierra. Justo eget nada terra videa magna derita valies darta donna mare fermentum iaculis eu non diam phasellus.</p>
              <div class="social-links mt-3">
                <a href="www.facebook.com" class="twitter"><BsTwitter /></a>
                <a href="www.facebook.com" class="facebook"><BsFacebook /></a>
                <a href="www.facebook.com" class="instagram"><BsInstagram /></a>
                <a href="www.facebook.com" class="linkedin"><BsLinkedin /></a>
              </div>
            </div>

            <div class="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><BsChevronRight />  <a href="www.facebook.comhome">Home</a></li>
                <li><BsChevronRight /> <a href="www.facebook.comabout">About us</a></li>
                <li><BsChevronRight />  <a href="www.facebook.comhome">Services</a></li>
                <li><BsChevronRight />  <a href="www.facebook.comhome">Terms of service</a></li>
                <li><BsChevronRight /> <a href="www.facebook.comhome">Privacy policy</a></li>
              </ul>
            </div>

            <div class="col-lg-2 col-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li><BsChevronRight /> <a href="www.facebook.com">Web Design</a></li>
                <li><BsChevronRight /> <a href="www.facebook.com">Web Development</a></li>
                <li><BsChevronRight /> <a href="www.facebook.com">Product Management</a></li>
                <li><BsChevronRight /> <a href="www.facebook.com">Marketing</a></li>
                <li><BsChevronRight /> <a href="www.facebook.com">Graphic Design</a></li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                Jinja road street <br />
                Kampala, Uganda<br />
                Uganda <br /><br />
                <strong>Phone:</strong> +256 78684 2944<br />
                <strong>Email:</strong> info@kesilo.com<br />
              </p>

            </div>


          </div>
        </div>
      </div>

      <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>Kesilo</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        {/* Designed by <a href="">Kesilo</a> */}
      </div>
    </div>


    </footer>
  )
}

export default Footer;