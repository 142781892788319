import React from 'react';
import './contact.css';

import {BsGeoAltFill, BsTelephone, BsEnvelope, BsClock} from 'react-icons/bs';

const Contact = () => {
  return (
    <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
            <header class="section-header section-contact">
                <h2>Contact</h2>
                <p className='gradient__text'>Contact Us</p>
            </header>

            <div class="row gy-4">

                <div class="col-lg-6">
                    <div class="row gy-4">
                        <div class="col-md-6">
                            <div class="info-box">
                                <i class="bi bi-geo-alt"><BsGeoAltFill /></i>
                                <h3>Address</h3>
                                <p>Jinja Road,<br />Uganda, Kampala 535022</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="info-box">
                                <i class="bi bi-telephone"><BsTelephone /></i>
                                <h3>Call Us</h3>
                                <p>+256 786 8429 44<br/>+256 705 4128 94</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="info-box">
                                <i class="bi bi-envelope"><BsEnvelope/></i>
                                <h3>Email Us</h3>
                                <p>babskevon@gmail.com<br/>kevin@kesilo.com</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="info-box">
                                <i class="bi bi-clock"><BsClock/></i>
                                <h3>Open Hours</h3>
                                <p>Monday - Friday<br/>9:00AM - 05:00PM</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <form class="php-email-form">
                        <div class="row gy-4">
                            <div class="col-md-6">
                                <input type="text" name="name" class="form-control" placeholder="Your Name" required />
                            </div>

                            <div class="col-md-6 ">
                                <input type="email" class="form-control" name="email" placeholder="Your Email" required />
                            </div>

                            <div class="col-md-12">
                                <input type="text" class="form-control" name="subject" placeholder="Subject" required />
                            </div>

                            <div class="col-md-12">
                                <textarea class="form-control" name="message" rows="6" placeholder="Message" required></textarea>
                            </div>

                            <div class="col-md-12 text-center">
                                <div class="loading">Loading</div>
                                <div class="error-message"></div>
                                <div class="sent-message">Your message has been sent. Thank you!</div>

                                <button type="submit">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    </section>
  )
}

export default Contact
