import React from 'react';
import './services.css';

import {BsFillArrowRightCircleFill} from 'react-icons/bs';
import {RiDiscussLine} from 'react-icons/ri';

const Services = () => {
  return (
    <section id="services" class="services">
        <div class="container" data-aos="fade-up">
            <header class="section-header section-services">
                <h2>Services</h2>
                <p className='gradient__text'>Veritatis et dolores facere numquam et praesentium</p>
            </header>

            <div class="row gy-4">


                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                    <div class="service-box blue">
                        <i class="ri-discuss-line icon"><RiDiscussLine /></i>
                        <h3>Nesciunt Mete</h3>
                        <p>Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure perferendis tempore et consequatur.</p>
                        <a href="www.facebook.com" class="read-more"><span>Read More</span> <BsFillArrowRightCircleFill /></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                    <div class="service-box orange">
                    <i class="ri-discuss-line icon"><RiDiscussLine /></i>
                        <h3>Eosle Commodi</h3>
                        <p>Ut autem aut autem non a. Sint sint sit facilis nam iusto sint. Libero corrupti neque eum hic non ut nesciunt dolorem.</p>
                        <a href="www.facebook.com" class="read-more"><span>Read More</span> <BsFillArrowRightCircleFill /></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                    <div class="service-box green">
                        <i class="ri-discuss-line icon"><RiDiscussLine /></i>
                        <h3>Ledo Markt</h3>
                        <p>Ut excepturi voluptatem nisi sed. Quidem fuga consequatur. Minus ea aut. Vel qui id voluptas adipisci eos earum corrupti.</p>
                        <a href="www.facebook.com" class="read-more"><span>Read More</span> <BsFillArrowRightCircleFill /></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                    <div class="service-box red">
                        <i class="ri-discuss-line icon"><RiDiscussLine /></i>
                        <h3>Asperiores Commodi</h3>
                        <p>Non et temporibus minus omnis sed dolor esse consequatur. Cupiditate sed error ea fuga sit provident adipisci neque.</p>
                        <a href="www.facebook.com" class="read-more"><span>Read More</span> <BsFillArrowRightCircleFill /></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                    <div class="service-box purple">
                        <i class="ri-discuss-line icon"><RiDiscussLine /></i>
                        <h3>Velit Doloremque.</h3>
                        <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
                        <a href="www.facebook.com" class="read-more"><span>Read More</span> <BsFillArrowRightCircleFill /></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
                    <div class="service-box pink">
                        <i class="ri-discuss-line icon"><RiDiscussLine /></i>
                        <h3>Dolori Architecto</h3>
                        <p>Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non et debitis iure. Corrupti recusandae ducimus enim.</p>
                        <a href="www.facebook.com" class="read-more"><span>Read More</span> <BsFillArrowRightCircleFill /></a>
                    </div>
                </div>



            </div>
        </div>
    </section>
  )
}

export default Services
